@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap");
.row {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }

.row.reverse {
  flex-direction: row-reverse; }

.row.natural-height {
  align-items: flex-start; }

.row.flex-column {
  flex-direction: column; }

.col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0.5rem; }

.col.reverse {
  flex-direction: column-reverse; }

.first {
  order: -1; }

.last {
  order: 1; }

.align-start {
  align-self: flex-start; }

.align-end {
  align-self: flex-end; }

.align-center {
  align-self: center; }

.align-baseline {
  align-self: baseline; }

.align-stretch {
  align-self: stretch; }

.col-xs {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0.5rem; }

.col-xs-1 {
  box-sizing: border-box;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
  padding: 0.5rem; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-2 {
  box-sizing: border-box;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
  padding: 0.5rem; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-3 {
  box-sizing: border-box;
  flex-basis: 25%;
  max-width: 25%;
  padding: 0.5rem; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
  padding: 0.5rem; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-5 {
  box-sizing: border-box;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
  padding: 0.5rem; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-6 {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0.5rem; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
  padding: 0.5rem; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-8 {
  box-sizing: border-box;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
  padding: 0.5rem; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-9 {
  box-sizing: border-box;
  flex-basis: 75%;
  max-width: 75%;
  padding: 0.5rem; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
  padding: 0.5rem; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-11 {
  box-sizing: border-box;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
  padding: 0.5rem; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-12 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0.5rem; }

.col-xs-offset-12 {
  margin-left: 100%; }

.row.start-xs {
  justify-content: flex-start; }

.row.center-xs {
  justify-content: center; }

.row.end-xs {
  justify-content: flex-end; }

.row.top-xs {
  align-items: flex-start; }

.row.middle-xs {
  align-items: center; }

.row.bottom-xs {
  align-items: flex-end; }

.row.around-xs {
  justify-content: space-around; }

.row.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 41rem) {
  .col-sm {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem; }
  .col-sm-1 {
    box-sizing: border-box;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    padding: 0.5rem; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-2 {
    box-sizing: border-box;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    padding: 0.5rem; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    padding: 0.5rem; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-5 {
    box-sizing: border-box;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    padding: 0.5rem; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    padding: 0.5rem; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-8 {
    box-sizing: border-box;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    padding: 0.5rem; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    padding: 0.5rem; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-11 {
    box-sizing: border-box;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    padding: 0.5rem; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .row.start-sm {
    justify-content: flex-start; }
  .row.center-sm {
    justify-content: center; }
  .row.end-sm {
    justify-content: flex-end; }
  .row.top-sm {
    align-items: flex-start; }
  .row.middle-sm {
    align-items: center; }
  .row.bottom-sm {
    align-items: flex-end; }
  .row.around-sm {
    justify-content: space-around; }
  .row.between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 65rem) {
  .col-md {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem; }
  .col-md-1 {
    box-sizing: border-box;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    padding: 0.5rem; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-2 {
    box-sizing: border-box;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    padding: 0.5rem; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    padding: 0.5rem; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-5 {
    box-sizing: border-box;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    padding: 0.5rem; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    padding: 0.5rem; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-8 {
    box-sizing: border-box;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    padding: 0.5rem; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    padding: 0.5rem; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-11 {
    box-sizing: border-box;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    padding: 0.5rem; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .row.start-md {
    justify-content: flex-start; }
  .row.center-md {
    justify-content: center; }
  .row.end-md {
    justify-content: flex-end; }
  .row.top-md {
    align-items: flex-start; }
  .row.middle-md {
    align-items: center; }
  .row.bottom-md {
    align-items: flex-end; }
  .row.around-md {
    justify-content: space-around; }
  .row.between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 91rem) {
  .col-lg {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem; }
  .col-lg-1 {
    box-sizing: border-box;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    padding: 0.5rem; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-2 {
    box-sizing: border-box;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    padding: 0.5rem; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    padding: 0.5rem; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-5 {
    box-sizing: border-box;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    padding: 0.5rem; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    padding: 0.5rem; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-8 {
    box-sizing: border-box;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    padding: 0.5rem; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    padding: 0.5rem; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-11 {
    box-sizing: border-box;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    padding: 0.5rem; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .row.start-lg {
    justify-content: flex-start; }
  .row.center-lg {
    justify-content: center; }
  .row.end-lg {
    justify-content: flex-end; }
  .row.top-lg {
    align-items: flex-start; }
  .row.middle-lg {
    align-items: center; }
  .row.bottom-lg {
    align-items: flex-end; }
  .row.around-lg {
    justify-content: space-around; }
  .row.between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

@media only screen and (min-width: 121rem) {
  .col-xl {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem; }
  .col-xl-1 {
    box-sizing: border-box;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    padding: 0.5rem; }
  .col-xl-offset-1 {
    margin-left: 8.33333333%; }
  .col-xl-2 {
    box-sizing: border-box;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    padding: 0.5rem; }
  .col-xl-offset-2 {
    margin-left: 16.66666667%; }
  .col-xl-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem; }
  .col-xl-offset-3 {
    margin-left: 25%; }
  .col-xl-4 {
    box-sizing: border-box;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    padding: 0.5rem; }
  .col-xl-offset-4 {
    margin-left: 33.33333333%; }
  .col-xl-5 {
    box-sizing: border-box;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    padding: 0.5rem; }
  .col-xl-offset-5 {
    margin-left: 41.66666667%; }
  .col-xl-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem; }
  .col-xl-offset-6 {
    margin-left: 50%; }
  .col-xl-7 {
    box-sizing: border-box;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    padding: 0.5rem; }
  .col-xl-offset-7 {
    margin-left: 58.33333333%; }
  .col-xl-8 {
    box-sizing: border-box;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    padding: 0.5rem; }
  .col-xl-offset-8 {
    margin-left: 66.66666667%; }
  .col-xl-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem; }
  .col-xl-offset-9 {
    margin-left: 75%; }
  .col-xl-10 {
    box-sizing: border-box;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    padding: 0.5rem; }
  .col-xl-offset-10 {
    margin-left: 83.33333333%; }
  .col-xl-11 {
    box-sizing: border-box;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    padding: 0.5rem; }
  .col-xl-offset-11 {
    margin-left: 91.66666667%; }
  .col-xl-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem; }
  .col-xl-offset-12 {
    margin-left: 100%; }
  .row.start-xl {
    justify-content: flex-start; }
  .row.center-xl {
    justify-content: center; }
  .row.end-xl {
    justify-content: flex-end; }
  .row.top-xl {
    align-items: flex-start; }
  .row.middle-xl {
    align-items: center; }
  .row.bottom-xl {
    align-items: flex-end; }
  .row.around-xl {
    justify-content: space-around; }
  .row.between-xl {
    justify-content: space-between; }
  .first-xl {
    order: -1; }
  .last-xl {
    order: 1; } }

.col-gutter-lr {
  padding: 0 0.5rem; }

.col-no-gutter {
  padding: 0; }

.show {
  display: block !important; }

.row.show {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.hide {
  display: none !important; }

.show-xs {
  display: block !important; }

.row.show-xs {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.hide-xs {
  display: none !important; }

@media only screen and (max-width: 40rem) {
  .show-xs-only {
    display: block !important; }
  .row.show-xs-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-xs-only {
    display: none !important; } }

@media only screen and (min-width: 41rem) {
  .show-sm {
    display: block !important; }
  .row.show-sm {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-sm {
    display: none !important; } }

@media only screen and (min-width: 41rem) and (max-width: 64rem) {
  .show-sm-only {
    display: block !important; }
  .row.show-sm-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-sm-only {
    display: none !important; } }

@media only screen and (min-width: 65rem) {
  .show-md {
    display: block !important; }
  .row.show-md {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-md {
    display: none !important; } }

@media only screen and (min-width: 65rem) and (max-width: 90rem) {
  .show-md-only {
    display: block !important; }
  .row.show-md-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-md-only {
    display: none !important; } }

@media only screen and (min-width: 91rem) {
  .show-lg {
    display: block !important; }
  .row.show-lg {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-lg {
    display: none !important; } }

@media only screen and (min-width: 91rem) and (max-width: 120rem) {
  .show-lg-only {
    display: block !important; }
  .row.show-lg-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-lg-only {
    display: none !important; } }

@media only screen and (min-width: 121rem) {
  .show-xl {
    display: block !important; }
  .row.show-xl {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .hide-xl {
    display: none !important; } }

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

/* toast styles */
.toast-title {
  font-weight: bold; }

.toast-message {
  word-wrap: break-word; }

.toast-message a,
.toast-message label {
  color: #FFFFFF; }

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */ }

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4; }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0; }

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999; }

.toast-container * {
  box-sizing: border-box; }

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF; }

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer; }

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E"); }

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E"); }

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E"); }

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E"); }

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto; }

.toast-success {
  background-color: #51A351; }

.toast-error {
  background-color: #BD362F; }

.toast-info {
  background-color: #2F96B4; }

.toast-warning {
  background-color: #F89406; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4; }

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em; } }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  font-family: "Nunito", Helvetica, sans-serif;
  line-height: 1.5; }

body {
  color: #282832;
  font-size: 16px; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 16px; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 26px;
  text-align: left;
  margin-left: initial !important;
  margin-right: initial !important;
  margin-bottom: 12px !important; }

b {
  font-weight: bold; }

a {
  color: inherit; }

.title {
  font-size: 1em;
  margin: 31px 31px 16px;
  font-weight: 900;
  text-transform: uppercase; }

h1, h2, h3, h4, h5 {
  padding-bottom: 10px; }

blockquote {
  padding: 15px 15px 20px;
  color: #353535; }

strong {
  font-weight: 600; }

small {
  font-size: 0.8em;
  color: #B1B1BB; }

span {
  font-size: 16px; }

/*
  It's not a font, I know, but can be one!
  */
.icon {
  max-width: 60px; }
  .icon svg {
    display: flex; }

@media all and (max-width: 600px) {
  body, span {
    font-size: 14px; } }

.btn {
  border: none;
  background: transparent;
  padding: 4px 5px;
  line-height: 16px;
  font-size: 12px;
  color: inherit;
  border-radius: 6px;
  min-width: 26px;
  min-height: 26px; }
  .btn > .btn {
    margin-left: 5px; }
  .btn-arrow {
    border: none;
    box-shadow: none;
    color: #FF7900;
    padding: 1px 6px; }
  .btn-blue {
    border: none;
    box-shadow: none;
    color: #7E7E7E;
    padding: 1px 6px; }
  .btn-border {
    border: 1px solid; }
  .btn-underline span {
    border-bottom: 1px solid #FF7900;
    border-radius: 0 !important; }
  .btn-underline fa-icon {
    font-size: 0.3em;
    color: #FF7900;
    margin: 5px; }
  .btn-big {
    font-size: 1em;
    letter-spacing: 0.04px;
    line-height: 20px;
    padding: 10px 8%; }
  .btn-actived {
    color: #fff;
    background: #282832; }
  .btn-darked, .btn-gray {
    border-radius: 0 !important;
    border: 0;
    font-weight: 900;
    text-transform: uppercase;
    box-shadow: none;
    color: #fff;
    background: #282832;
    margin: 5px auto;
    font-size: 1em;
    letter-spacing: 0.04px;
    line-height: 20px;
    padding: 10px 50px; }
  .btn-gray {
    color: #fff;
    background: #b1b1bb; }
  .btn-close {
    display: flex;
    font-size: 2em;
    background: transparent;
    border: 0;
    padding: 18px 10px;
    box-shadow: none; }
    @media (min-width: 1280px) {
      .btn-close {
        display: none; } }
  .btn > span > .icon {
    max-width: 14px;
    margin: auto 5px; }

.row-container {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto; }
  @media all and (max-width: 600px) {
    .row-container {
      margin: auto; } }

.g-margin > [class*="col"] {
  margin: 15px;
  margin-top: 0; }

.push-left {
  margin-right: auto; }

.push-right {
  margin-left: auto; }

.full-width {
  width: 100%; }

.hr {
  width: 100%;
  margin: 20px auto;
  border: 0;
  border-top: 1px solid #f6f6f6; }

.flex {
  display: flex; }
  .flex-center {
    align-items: center;
    justify-content: center; }
  .flex-right {
    align-items: flex-start;
    justify-content: flex-end; }
  .flex-evenly {
    align-items: space-evenly;
    justify-content: space-evenly; }
  .flex-between {
    align-items: space-between;
    justify-content: space-between; }
  .flex-row {
    flex-direction: row; }
  .flex-column {
    flex-direction: column; }

@media screen and (max-width: -1px) {
  .hidden-xs-down {
    display: none !important; } }

.hidden-xs-up {
  display: none !important; }

@media screen and (max-width: 575px) {
  .hidden-sm-down {
    display: none !important; } }

@media screen and (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-md-down {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .hidden-lg-down {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media screen and (max-width: 1199px) {
  .hidden-xl-down {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.container {
  max-width: 768px;
  margin: 0 auto; }
  .container--fluid {
    margin: 0;
    max-width: 100%; }
  .container__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .container__col-offset-0 {
    margin-left: 0; }
  .container__col-1 {
    flex-basis: 8.33333333%; }
  .container__col-offset-1 {
    margin-left: 8.33333333%; }
  .container__col-2 {
    flex-basis: 16.66666667%; }
  .container__col-offset-2 {
    margin-left: 16.66666667%; }
  .container__col-3 {
    flex-basis: 25%; }
  .container__col-offset-3 {
    margin-left: 25%; }
  .container__col-4 {
    flex-basis: 33.33333333%; }
  .container__col-offset-4 {
    margin-left: 33.33333333%; }
  .container__col-5 {
    flex-basis: 41.66666667%; }
  .container__col-offset-5 {
    margin-left: 41.66666667%; }
  .container__col-6 {
    flex-basis: 50%; }
  .container__col-offset-6 {
    margin-left: 50%; }
  .container__col-7 {
    flex-basis: 58.33333333%; }
  .container__col-offset-7 {
    margin-left: 58.33333333%; }
  .container__col-8 {
    flex-basis: 66.66666667%; }
  .container__col-offset-8 {
    margin-left: 66.66666667%; }
  .container__col-9 {
    flex-basis: 75%; }
  .container__col-offset-9 {
    margin-left: 75%; }
  .container__col-10 {
    flex-basis: 83.33333333%; }
  .container__col-offset-10 {
    margin-left: 83.33333333%; }
  .container__col-11 {
    flex-basis: 91.66666667%; }
  .container__col-offset-11 {
    margin-left: 91.66666667%; }
  .container__col-12 {
    flex-basis: 100%; }
  .container__col-offset-12 {
    margin-left: 100%; }
  @media screen and (min-width: 576px) {
    .container__col-sm-offset-0 {
      margin-left: 0; }
    .container__col-sm-1 {
      flex-basis: 8.33333333%; }
    .container__col-sm-offset-1 {
      margin-left: 8.33333333%; }
    .container__col-sm-2 {
      flex-basis: 16.66666667%; }
    .container__col-sm-offset-2 {
      margin-left: 16.66666667%; }
    .container__col-sm-3 {
      flex-basis: 25%; }
    .container__col-sm-offset-3 {
      margin-left: 25%; }
    .container__col-sm-4 {
      flex-basis: 33.33333333%; }
    .container__col-sm-offset-4 {
      margin-left: 33.33333333%; }
    .container__col-sm-5 {
      flex-basis: 41.66666667%; }
    .container__col-sm-offset-5 {
      margin-left: 41.66666667%; }
    .container__col-sm-6 {
      flex-basis: 50%; }
    .container__col-sm-offset-6 {
      margin-left: 50%; }
    .container__col-sm-7 {
      flex-basis: 58.33333333%; }
    .container__col-sm-offset-7 {
      margin-left: 58.33333333%; }
    .container__col-sm-8 {
      flex-basis: 66.66666667%; }
    .container__col-sm-offset-8 {
      margin-left: 66.66666667%; }
    .container__col-sm-9 {
      flex-basis: 75%; }
    .container__col-sm-offset-9 {
      margin-left: 75%; }
    .container__col-sm-10 {
      flex-basis: 83.33333333%; }
    .container__col-sm-offset-10 {
      margin-left: 83.33333333%; }
    .container__col-sm-11 {
      flex-basis: 91.66666667%; }
    .container__col-sm-offset-11 {
      margin-left: 91.66666667%; }
    .container__col-sm-12 {
      flex-basis: 100%; }
    .container__col-sm-offset-12 {
      margin-left: 100%; } }
  @media screen and (min-width: 768px) {
    .container__col-md-offset-0 {
      margin-left: 0; }
    .container__col-md-1 {
      flex-basis: 8.33333333%; }
    .container__col-md-offset-1 {
      margin-left: 8.33333333%; }
    .container__col-md-2 {
      flex-basis: 16.66666667%; }
    .container__col-md-offset-2 {
      margin-left: 16.66666667%; }
    .container__col-md-3 {
      flex-basis: 25%; }
    .container__col-md-offset-3 {
      margin-left: 25%; }
    .container__col-md-4 {
      flex-basis: 33.33333333%; }
    .container__col-md-offset-4 {
      margin-left: 33.33333333%; }
    .container__col-md-5 {
      flex-basis: 41.66666667%; }
    .container__col-md-offset-5 {
      margin-left: 41.66666667%; }
    .container__col-md-6 {
      flex-basis: 50%; }
    .container__col-md-offset-6 {
      margin-left: 50%; }
    .container__col-md-7 {
      flex-basis: 58.33333333%; }
    .container__col-md-offset-7 {
      margin-left: 58.33333333%; }
    .container__col-md-8 {
      flex-basis: 66.66666667%; }
    .container__col-md-offset-8 {
      margin-left: 66.66666667%; }
    .container__col-md-9 {
      flex-basis: 75%; }
    .container__col-md-offset-9 {
      margin-left: 75%; }
    .container__col-md-10 {
      flex-basis: 83.33333333%; }
    .container__col-md-offset-10 {
      margin-left: 83.33333333%; }
    .container__col-md-11 {
      flex-basis: 91.66666667%; }
    .container__col-md-offset-11 {
      margin-left: 91.66666667%; }
    .container__col-md-12 {
      flex-basis: 100%; }
    .container__col-md-offset-12 {
      margin-left: 100%; } }
  @media screen and (min-width: 992px) {
    .container__col-lg-offset-0 {
      margin-left: 0; }
    .container__col-lg-1 {
      flex-basis: 8.33333333%; }
    .container__col-lg-offset-1 {
      margin-left: 8.33333333%; }
    .container__col-lg-2 {
      flex-basis: 16.66666667%; }
    .container__col-lg-offset-2 {
      margin-left: 16.66666667%; }
    .container__col-lg-3 {
      flex-basis: 25%; }
    .container__col-lg-offset-3 {
      margin-left: 25%; }
    .container__col-lg-4 {
      flex-basis: 33.33333333%; }
    .container__col-lg-offset-4 {
      margin-left: 33.33333333%; }
    .container__col-lg-5 {
      flex-basis: 41.66666667%; }
    .container__col-lg-offset-5 {
      margin-left: 41.66666667%; }
    .container__col-lg-6 {
      flex-basis: 50%; }
    .container__col-lg-offset-6 {
      margin-left: 50%; }
    .container__col-lg-7 {
      flex-basis: 58.33333333%; }
    .container__col-lg-offset-7 {
      margin-left: 58.33333333%; }
    .container__col-lg-8 {
      flex-basis: 66.66666667%; }
    .container__col-lg-offset-8 {
      margin-left: 66.66666667%; }
    .container__col-lg-9 {
      flex-basis: 75%; }
    .container__col-lg-offset-9 {
      margin-left: 75%; }
    .container__col-lg-10 {
      flex-basis: 83.33333333%; }
    .container__col-lg-offset-10 {
      margin-left: 83.33333333%; }
    .container__col-lg-11 {
      flex-basis: 91.66666667%; }
    .container__col-lg-offset-11 {
      margin-left: 91.66666667%; }
    .container__col-lg-12 {
      flex-basis: 100%; }
    .container__col-lg-offset-12 {
      margin-left: 100%; } }
  @media screen and (min-width: 1200px) {
    .container__col-xl-offset-0 {
      margin-left: 0; }
    .container__col-xl-1 {
      flex-basis: 8.33333333%; }
    .container__col-xl-offset-1 {
      margin-left: 8.33333333%; }
    .container__col-xl-2 {
      flex-basis: 16.66666667%; }
    .container__col-xl-offset-2 {
      margin-left: 16.66666667%; }
    .container__col-xl-3 {
      flex-basis: 25%; }
    .container__col-xl-offset-3 {
      margin-left: 25%; }
    .container__col-xl-4 {
      flex-basis: 33.33333333%; }
    .container__col-xl-offset-4 {
      margin-left: 33.33333333%; }
    .container__col-xl-5 {
      flex-basis: 41.66666667%; }
    .container__col-xl-offset-5 {
      margin-left: 41.66666667%; }
    .container__col-xl-6 {
      flex-basis: 50%; }
    .container__col-xl-offset-6 {
      margin-left: 50%; }
    .container__col-xl-7 {
      flex-basis: 58.33333333%; }
    .container__col-xl-offset-7 {
      margin-left: 58.33333333%; }
    .container__col-xl-8 {
      flex-basis: 66.66666667%; }
    .container__col-xl-offset-8 {
      margin-left: 66.66666667%; }
    .container__col-xl-9 {
      flex-basis: 75%; }
    .container__col-xl-offset-9 {
      margin-left: 75%; }
    .container__col-xl-10 {
      flex-basis: 83.33333333%; }
    .container__col-xl-offset-10 {
      margin-left: 83.33333333%; }
    .container__col-xl-11 {
      flex-basis: 91.66666667%; }
    .container__col-xl-offset-11 {
      margin-left: 91.66666667%; }
    .container__col-xl-12 {
      flex-basis: 100%; }
    .container__col-xl-offset-12 {
      margin-left: 100%; } }

.no-padding {
  padding: 0px; }

.no-margin {
  margin-bottom: 0px; }

.form .form-group {
  position: relative;
  margin-bottom: 25px;
  margin-top: 10px; }
  .form .form-group:first-child {
    margin-top: 25px; }
  .form .form-group label {
    position: absolute;
    color: #999;
    font-size: 1.2em;
    top: 10px;
    cursor: text;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all; }
  .form .form-group.toogle-eye bari-button {
    position: absolute;
    top: 10px;
    right: 10px; }
  .form .form-group.valid-check::after {
    content: ' ';
    background: url("assets/images/icons/check-green.svg");
    position: absolute;
    top: 20px;
    right: 6px;
    width: 14px;
    height: 10px;
    color: #3FC751; }
  .form .form-group.invalid::after {
    content: ' ';
    background: url("assets/images/icons/x.svg");
    position: absolute;
    top: 20px;
    right: 6px;
    width: 12px;
    height: 12px;
    color: red; }
  .form .form-group.invalid input {
    border-color: red !important; }
  .form .form-group.tree-dots {
    display: flex; }
    .form .form-group.tree-dots input {
      margin-bottom: 0;
      width: auto;
      flex-grow: 1;
      font-family: 'Nunito'; }
    .form .form-group.tree-dots::before {
      content: "●●●● ●●●● ●●●●";
      font-size: 18px;
      padding: 10px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      border: none;
      border-bottom: 1px solid #b1b1bb;
      color: #282832;
      display: block ruby; }
    @media all and (max-width: 600px) {
      .form .form-group.tree-dots input, .form .form-group.tree-dots::before {
        width: 100%; } }
  .form .form-group input,
  .form .form-group select {
    font-size: 18px;
    padding: 10px 15px 5px 0;
    display: block;
    border: none;
    border-bottom: 1px solid #b1b1bb;
    border-radius: 0;
    width: 100%;
    color: #282832;
    margin-bottom: 20px; }
    .form .form-group input.input-with-feedback,
    .form .form-group select.input-with-feedback {
      margin-bottom: 0; }
      .form .form-group input.input-with-feedback:focus,
      .form .form-group select.input-with-feedback:focus {
        border-color: #f44336; }
      .form .form-group input.input-with-feedback:focus ~ label,
      .form .form-group input.input-with-feedback:valid:not(.cbx) ~ label,
      .form .form-group input.input-with-feedback:disabled:not(.cbx) ~ label,
      .form .form-group select.input-with-feedback:focus ~ label,
      .form .form-group select.input-with-feedback:valid:not(.cbx) ~ label,
      .form .form-group select.input-with-feedback:disabled:not(.cbx) ~ label {
        color: #f44336; }
    .form .form-group input:focus,
    .form .form-group select:focus {
      outline: none;
      border-color: #7E7E7E; }
      .form .form-group input:focus ~ .highlight,
      .form .form-group select:focus ~ .highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease; }
    .form .form-group input:valid.ng-valid,
    .form .form-group select:valid.ng-valid {
      border-color: #3FC751; }
    .form .form-group input:focus ~ label,
    .form .form-group input:valid:not(.cbx) ~ label,
    .form .form-group input:disabled:not(.cbx) ~ label,
    .form .form-group select:focus ~ label,
    .form .form-group select:valid:not(.cbx) ~ label,
    .form .form-group select:disabled:not(.cbx) ~ label {
      top: -10px;
      font-size: 0.8em; }
  .form .form-group .input-feedback {
    position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
    padding: 10px;
    padding-bottom: 0;
    color: #f44336; }
  .form .form-group .lbl {
    top: 0;
    position: relative;
    display: block;
    height: 20px;
    width: 44px;
    background: #c1c1c7;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease; }
    .form .form-group .lbl:after {
      position: absolute;
      left: -2px;
      top: -3px;
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 100px;
      background: #282832;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      content: "";
      transition: all 0.3s ease; }
    .form .form-group .lbl:active:after {
      transform: scale(1.15, 0.85); }
  .form .form-group .cntr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .form .form-group .press {
    margin-bottom: 40px; }
  .form .form-group .hidden {
    display: none; }

.checkbox input[type=checkbox] {
  display: none; }
  .checkbox input[type=checkbox] + label {
    display: flex;
    height: 20px;
    padding: 0 0 0 25px;
    margin: 0 10px 0 0;
    position: relative;
    align-items: center;
    font-size: 16px; }
    .checkbox input[type=checkbox] + label a {
      margin-right: 5px;
      color: #000; }
    .checkbox input[type=checkbox] + label::before {
      content: ' ';
      display: flex;
      background: #fff;
      border: 2px solid #000;
      width: 18px;
      height: 18px;
      border-radius: 5px;
      margin-right: 5px;
      line-height: 1;
      font-weight: 100;
      justify-content: center;
      align-items: center;
      font-size: 14px; }
  .checkbox input[type=checkbox]:checked + label::before {
    content: '✓';
    background: #0090FF;
    border-color: #0090FF;
    color: #fff; }

@-webkit-keyframes inputHighlighter {
  from {
    background: #7E7E7E; }
  to {
    width: 0;
    background: transparent; } }

@-moz-keyframes inputHighlighter {
  from {
    background: #7E7E7E; }
  to {
    width: 0;
    background: transparent; } }

@keyframes inputHighlighter {
  from {
    background: #7E7E7E; }
  to {
    width: 0;
    background: transparent; } }

.input-active-pass {
  outline: none;
  border-color: #ff7901 !important; }

.label-active-pass {
  top: -10px !important;
  font-size: 0.8em !important;
  color: #ff7901 !important; }

.collapsible-animation {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0.7s, opacity 0.7s ease-in-out; }
  .collapsible-animation.hidden {
    opacity: 0;
    visibility: hidden;
    display: none; }

.menu-animation {
  overflow-x: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.56, 0.55, 0.65, 0.64); }
  @media all and (max-width: 768px) {
    .menu-animation {
      max-width: 0;
      opacity: 0; } }
  .menu-animation.hidden {
    max-width: 0;
    opacity: 0; }
  .menu-animation.show {
    max-width: 80%;
    opacity: 1; }

.ph-loading, .ph-loading-dark {
  position: relative;
  overflow: hidden;
  color: transparent !important; }
  .ph-loading::before, .ph-loading-dark::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8;
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px; }
  .ph-loading-dark::before {
    background: #31313145;
    background: linear-gradient(to right, #04040433 2%, #3131318a 18%, #04040433 33%);
    background-size: 1300px; }

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0; }
  100% {
    background-position: 650px 0; } }

.input-loading {
  position: relative; }
  .input-loading::after, .input-loading::before {
    animation: around 5.4s infinite;
    content: "";
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    bottom: 7px;
    right: 0;
    animation: around 0.7s ease-in-out infinite; }
  .input-loading::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent; }

@keyframes around {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

html, body {
  height: 100%;
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit;
  /* https://css-tricks.com/box-sizing/ */ }

body {
  font-weight: normal;
  background-color: #EFEFEF;
  color: #282832;
  -webkit-overflow-scrolling: touch; }

h3 {
  color: #FF7900;
  font-weight: bold; }

p {
  margin: 5px auto; }

.toast-top-center {
  top: 5%; }

button:focus, button::-moz-focus-inner {
  outline: none;
  border: 0; }
