@import "variables";

.form {
  .form-group {
    position: relative;
    margin-bottom: 25px;
    margin-top: 10px;

    &:first-child {
      margin-top: 25px;
    }

    label {
      position: absolute;
      color: #999;
      font-size: 1.2em;
      top: 10px;
      cursor: text;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    &.toogle-eye bari-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &.valid-check {
      &::after {
        content: ' ';
        background: url('assets/images/icons/check-green.svg');
        position: absolute;
        top: 20px;
        right: 6px;
        width: 14px;
        height: 10px;
        color: $green-color;
      }
    }

    &.invalid {
      &::after {
        content: ' ';
        background: url('assets/images/icons/x.svg');
        position: absolute;
        top: 20px;
        right: 6px;
        width: 12px;
        height: 12px;
        color: red;
      }

      input {
        border-color: red !important;
      }
    }

    &.tree-dots {
      display: flex;

      input {
        margin-bottom: 0;
        width: auto;
        flex-grow: 1;
        font-family: 'Nunito';
      }

      &::before {
        content: "●●●● ●●●● ●●●●";
        font-size: 18px;
        padding: 10px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        border: none;
        border-bottom: 1px solid #b1b1bb;
        color: $black-color-label-value;
        display: block ruby
      }

      @include mobile {
        input, &::before {
          width: 100%;
        }
      }
    }

    input,
    select {
      font-size: 18px;
      padding: 10px 15px 5px 0;
      display: block;
      border: none;
      border-bottom: 1px solid #b1b1bb;
      border-radius: 0;
      width: 100%;
      color: $black-color-label-value;
      margin-bottom: 20px;


      &.input-with-feedback {
        margin-bottom: 0;

        &:focus {
          border-color: #f44336;
        }

        &:focus ~ label,
        &:valid:not(.cbx) ~ label,
        &:disabled:not(.cbx) ~ label {
          color: #f44336;
        }
      }

      &:focus {
        outline: none;
        border-color: $btn-color-gray;

        & ~ .highlight {
          -webkit-animation: inputHighlighter 0.3s ease;
          -moz-animation: inputHighlighter 0.3s ease;
          animation: inputHighlighter 0.3s ease;
        }
      }

      &:valid.ng-valid {
        border-color: $green-color;
      }

      &:focus ~ label,
      &:valid:not(.cbx) ~ label,
      &:disabled:not(.cbx) ~ label {
        top: -10px;
        font-size: 0.8em;
      }
    }

    .input-feedback {
      position: relative;
      min-height: 18px;
      display: block;
      font-size: 12px;
      padding: 10px;
      padding-bottom: 0;
      color: #f44336;
    }

    .lbl {
      top: 0;
      position: relative;
      display: block;
      height: 20px;
      width: 44px;
      background: #c1c1c7;
      border-radius: 100px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:after {
        position: absolute;
        left: -2px;
        top: -3px;
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 100px;
        background: $black-color-label-value;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
        content: "";
        transition: all 0.3s ease;
      }

      &:active:after {
        transform: scale(1.15, 0.85);
      }
    }


    .cntr {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .press {
      margin-bottom: 40px;
    }

    .hidden {
      display: none;
    }
  }
}

.checkbox {

  input[type=checkbox] {
    display: none;

    & + label {
      display: flex;
      height: 20px;
      padding: 0 0 0 25px;
      margin: 0 10px 0 0;
      position: relative;
      align-items: center;
      font-size: 16px;

      a {
        margin-right: 5px;
        color: #000;
      }

      &::before {
        content: ' ';
        display: flex;
        background: #fff;
        border: 2px solid #000;
        width: 18px;
        height: 18px;
        border-radius: 5px;
        margin-right: 5px;
        line-height: 1;
        font-weight: 100;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }

    &:checked + label {
      &::before {
        content: '✓';
        background: $primary-color;
        border-color: $primary-color;
        color: #fff;
      }
    }
  }
}

@-webkit-keyframes inputHighlighter {
  from {
    background: $btn-color-gray;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: $btn-color-gray;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: $btn-color-gray;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.input-active-pass {
  outline: none;
  border-color: #ff7901 !important;
}

.label-active-pass {
  top: -10px !important;
  font-size: 0.8em !important;
  color: #ff7901 !important;
}
