@mixin linear-gradient ($deg, $color1, $color2, $star: 0%, $end: 100%) {
  background: $color1;
  background: -moz-linear-gradient($deg, $color1 $star, $color2 $end);
  background: -webkit-linear-gradient($deg, $color1 $star, $color2 $end);
  background: linear-gradient($deg, $color1 $star, $color2 $end);
}

$mobile-width: 600px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1280px;




@mixin mobile {
  @media all and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin dropdownmenu{
  @media all and (max-width: #{$tablet-width}) {
    @content;
  }
  
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width + 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin transition($time) {
  -webkit-transition: $time;
  -moz-transition: $time;
  -ms-transition: $time;
  -o-transition: $time;
  transition: $time;
}

@function pxToRem($px) {
  @return #{($px / 16)}rem;
}
