@import "./bari-theme";
@import "mixins";

@import url('https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap');

// Font-Family and Sizes
$font-family: 'Nunito', Helvetica, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;
$font-height: 1.5;
$font-size: 16px;
$font-size-mobile: 14px;

// Universal Fonts
* {
  font-family: $font-family;
  line-height: $font-height;
}

body {
  color: $dark-gray;
  font-size: $font-size;
}

// Font Sizes

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: $font-size;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 26px;
  text-align: left;
  margin-left: initial !important;
  margin-right: initial !important;
  margin-bottom: 12px !important;
}

b {
  font-weight: bold;
}

a {
  color: inherit;
}

.title{
  font-size: 1em;
  margin: 31px 31px 16px;
  font-weight: 900;
  text-transform: uppercase;
}

h1,h2,h3,h4,h5{
  padding-bottom: 10px;
}

blockquote{
  padding: 15px 15px 20px;
  color: rgb(53, 53, 53);
}

strong {
  font-weight: 600;
}

small {
  font-size: 0.8em;
  color: #B1B1BB;
}

span {
  font-size: $font-size;
}

/*
  It's not a font, I know, but can be one!
  */
.icon {
  max-width: 60px;
  svg {
    display: flex;
  }
}


@include mobile {
  body, span {
    font-size: $font-size-mobile;
  }
}
