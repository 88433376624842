@import "variables";
@import "mixins";

.row-container {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;

  @include mobile {
    margin: auto;
  }
}

// Add magin in coluns of grid
.g-margin{
  & > [class*="col"]{
    margin: 15px;
    margin-top: 0;
  }
}

// Flex push position
.push{
  &-left{
    margin-right: auto;
  }
  &-right{
    margin-left: auto;
  }
}

.full-width{
  width: 100%;
}

.hr {
  width: 100%;
  margin: 20px auto;
  border: 0;
  border-top: 1px solid $ultra-light-gray-color;
}

.flex{
  display: flex;

  &-center{
    align-items: center;
    justify-content: center;
  }
  
  &-right{
    align-items: flex-start;
    justify-content: flex-end;
  }

  &-evenly{
    align-items: space-evenly;
    justify-content: space-evenly;
  }

  &-between{
    align-items: space-between;
    justify-content: space-between;
  }

  &-row{
    flex-direction: row;
  }

  &-column{
    flex-direction: column;
  }
}

// Grid Size Fixed
$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;

$map-grid-props: (
        '': 0,
        '-sm': $grid__bp-sm,
        '-md': $grid__bp-md,
        '-lg': $grid__bp-lg,
        '-xl': $grid__bp-xl
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    &__col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      &__col#{$modifier}-#{$i} {
        flex-basis: (100 / ($grid-cols / $i) ) * 1%;
      }
      &__col#{$modifier}-offset-#{$i} {
        margin-left: (100 / ($grid-cols / $i) ) * 1%;
      }
    }
  }
}

@each $modifier , $breakpoint in $map-grid-props {
  @if($modifier == '') {
    $modifier: '-xs';
  }
  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  max-width: $grid__bp-md * 1px;
  margin: 0 auto;
  &--fluid {
    margin: 0;
    max-width: 100%;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @each $modifier , $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}

.no-padding {
  padding: 0px;
}
.no-margin{
  margin-bottom: 0px;
}