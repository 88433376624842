@import "src/assets/styles/mixins";
.btn{
  border: none;
  background: transparent;
  padding: 4px 5px;
  line-height: 16px;
  font-size: 12px;
  color: inherit;//$btn-color;
  // cursor: pointer;
  border-radius: 6px;
  min-width: 26px;
  min-height: 26px;

  & > .btn {
    margin-left: 5px;
  }
  span {
    // cursor: pointer;
  }

  &-arrow{
    border: none;
    box-shadow: none;
    color: $btn-color-orange;
    padding: 1px 6px;
  }

  &-blue{
    border: none;
    box-shadow: none;
    color: $btn-color-gray;
    padding: 1px 6px;
  }

  &-border {
    border: 1px solid;
  }

  &-underline{
    span {
      border-bottom: 1px solid $btn-color-orange;
      border-radius: 0 !important;
    }
    fa-icon{
      font-size: 0.3em;
      color: $btn-color-orange;
      margin: 5px;
    }
  }

  &-big {
    font-size: 1em;
    letter-spacing: 0.04px;
    line-height: 20px;
    padding: 10px 8%;
  }

  &-actived {
    color: #fff;
    background: $black-color-label-value;
  }

  &-darked {
    border-radius: 0 !important;
    border: 0;
    font-weight: 900;
    text-transform: uppercase;
    box-shadow: none;
    color: #fff;
    background: $black-color-label-value;
    margin: 5px auto;
    font-size: 1em;
    letter-spacing: 0.04px;
    line-height: 20px;
    padding: 10px 50px;
  }

  &-gray {
    @extend .btn-darked;
    color: #fff;
    background: #b1b1bb;
  }

  &-close {
    display: flex;
    font-size: 2em;
    background: transparent;
    border: 0;
    padding: 18px 10px;
    box-shadow: none;
    @include desktop {
      display: none;
    }
  }

  & > span > .icon {
    max-width: 14px;
    margin: auto 5px;
  }
}
