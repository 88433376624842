.ph-loading {

  position: relative;
  overflow: hidden;
  color: transparent !important;

  &::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    // Animation
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; // Fallback
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px; // Animation Area
  }

  &-dark {
    @extend .ph-loading;
    &::before {
      background: #31313145;
      background: linear-gradient(to right, #04040433 2%, #3131318a 18%, #04040433 33%);
      background-size: 1300px;
    }
  }

}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }

  100% {
    background-position: 650px 0;
  }
}

.input-loading {
  position: relative;
  &::after, &::before {
    animation: around 5.4s infinite;
    content: "";
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    bottom: 7px;
    right: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  &::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
