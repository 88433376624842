@import "variables";
@import "mixins";
@import "bari-theme";


.collapsible-animation {
  visibility: visible;
  opacity: 1;

  transition: visibility 0s linear 0.7s,
  opacity 0.7s ease-in-out;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    display: none;
  }


}

.menu-animation {
  overflow-x: hidden;

  @include dropdownmenu {
    max-width: 0;
    opacity: 0;
  }

  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(.56,.55,.65,.64);

  &.hidden {
    max-width: 0;
    opacity: 0;
  }

  &.show {
    max-width: 80%;
    opacity: 1;
  }

}
